import { Box, Button, Container, Grid, Typography } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getLoginDetails, generateOTP, validateEmail, login } from './APIRequests.js';
import PageLoader from '../../components/PageLoader.js';
import CustomAlert from '../../components/CustomAlert.js';
import CheckIcon from '@mui/icons-material/Check';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { TextInput } from '../../components/form/TextField';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import OTPLogin from '../../components/login/OTPLogin.js';
import PasswordLogin from '../../components/login/PasswordLogin.js';
import { GOOGLE_ANALYTICS_LABELS } from '../../utils/analytics.js';
import useTrackScrollDepth from '../../hooks/useTrackScrollDepth.js';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  SESSION_STORAGE_ACCESS_TOKEN,
  SESSION_STORAGE_EMAIL,
  SESSION_STORAGE_FIRSTNAME,
  SESSION_STORAGE_INITIALS,
  SESSION_STORAGE_IS_LOGGED_IN,
  SESSION_STORAGE_LASTNAME,
  SESSION_STORAGE_PLAN_ID,
  SESSION_STORAGE_REFRESH_TOKEN,
  SESSION_STORAGE_CLIENT
} from '../../utils/constants.js';
import ServerError from '../Static/ServerError.js';
import CircularProgress from '@mui/material/CircularProgress';

export default function Login() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { data, isLoading, error } = useQuery('getLoginDetails', getLoginDetails);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    }
  }, [location.state]);

  const [isAlertOpened, setIsAlertOpened] = useState(false);
  const [loginMethod, setLoginMethod] = useState('password');
  const [step, setStep] = useState(1);
  const [isLoadingNext, setIsLoadingNext] = useState(false);

  const [alertContent, setAlertContent] = useState({
    severity: '',
    title: '',
    body: ''
  });

  useTrackScrollDepth(GOOGLE_ANALYTICS_LABELS.login.name);

  const checkIconStyles = {
    color: 'white',
    backgroundColor: 'rgba(232, 242, 238, 0.3)',
    borderRadius: '50%'
  };

  const titleStyles = {
    fontFamily: 'Urbanist',
    fontSize: isMobile ? '35px' : '40px',
    fontWeight: isMobile ? 600 : 800,
    lineHeight: '48px'
  };

  const subTitleStyles = {
    fontFamily: 'Inter',
    fontSize: isMobile ? '16px' : '20px',
    fontWeight: isMobile ? 400 : 600,
    lineHeight: '48px'
  };

  const mainContainerStyles = {
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    marginBottom: '14px'
  };

  const imageBoxStyles = (image) => ({
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    width: '100%',
    height: '600px',
    position: 'relative',
    borderRadius: '10px',
    display: { xs: 'none', md: 'flex' }
  });

  const textBoxStyles = {
    position: 'absolute',
    bottom: '40px',
    color: 'white',
    left: '40px',
    width: '50%'
  };

  const formBoxStyles = {
    width: isMobile ? '100%' : '70%'
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required').email('Invalid email address'),
    loginMethod: Yup.string().required('Login method is required'),
    password: Yup.string().when('loginMethod', {
      is: (value) => value === 'password',
      then: (schema) => schema.required('Password is required'),
      otherwise: (schema) => schema
    }),
    otp: Yup.string().when('loginMethod', {
      is: (value) => value === 'otp',
      then: (schema) =>
        schema.required('OTP is required').length(4, 'OTP must be exactly 4 digits'),
      otherwise: (schema) => schema
    })
  });

  const nextEndIcon = isLoadingNext ? (
    <CircularProgress size={20} sx={{ color: 'rgba(0, 0, 0, 0.26)' }} />
  ) : null;

  return (
    <>
      {isLoading && <PageLoader />}
      {!isLoading && error && <ServerError />}
      {!isLoading && !error && (
        <Container maxWidth="xl">
          <Grid container columnSpacing={15} pt={12} pb={6}>
            <Grid item xs={12} md={6}>
              <Box sx={imageBoxStyles(process.env.REACT_APP_BACKEND_URL + data?.image)}>
                <Box sx={textBoxStyles}>
                  {data?.elements.map((element, index) => (
                    <Grid key={index} container sx={mainContainerStyles}>
                      <Grid item>
                        <CheckIcon sx={checkIconStyles} />
                      </Grid>
                      <Grid item ml={2} display={'flex'} flexDirection={'column'}>
                        <Typography color="white" fontWeight={'200'} variant="body">
                          {element}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <CustomAlert
                severity={alertContent.severity}
                title={alertContent.title}
                body={alertContent.body}
                isOpened={isAlertOpened}
                onAlertClose={() => setIsAlertOpened(false)}
              />
              <Box sx={formBoxStyles}>
                <Typography sx={titleStyles} paragraph={true}>
                  {data?.title}
                </Typography>
                <Typography sx={subTitleStyles} paragraph={true}>
                  My Global Travel Plus
                </Typography>
                <Typography
                  variant="body"
                  mb={2}
                  paragraph={true}
                  dangerouslySetInnerHTML={{
                    __html: data?.body
                  }}
                />
                <Formik
                  initialValues={{
                    email: email,
                    password: '',
                    otp: '',
                    loginMethod: loginMethod
                  }}
                  validationSchema={validationSchema}
                  validateOnBlur={false}
                  validateOnChange={false}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    login(values)
                      .then((data) => {
                        setSubmitting(false);
                        resetForm();
                        sessionStorage.setItem(SESSION_STORAGE_FIRSTNAME, data.firstname);
                        sessionStorage.setItem(SESSION_STORAGE_LASTNAME, data.lastname);
                        sessionStorage.setItem(SESSION_STORAGE_EMAIL, values.email);
                        sessionStorage.setItem(SESSION_STORAGE_INITIALS, data.initials);
                        sessionStorage.setItem(SESSION_STORAGE_ACCESS_TOKEN, data.access_token);
                        sessionStorage.setItem(SESSION_STORAGE_REFRESH_TOKEN, data.refresh_token);
                        sessionStorage.setItem(SESSION_STORAGE_PLAN_ID, data.plan_id);
                        sessionStorage.setItem(SESSION_STORAGE_CLIENT, data?.client);

                        if (data.first_time_login) {
                          navigate('/reset-password');
                        } else {
                          sessionStorage.setItem(SESSION_STORAGE_IS_LOGGED_IN, true);
                          navigate('/profile');
                        }
                      })
                      .catch((error) => {
                        setSubmitting(false);

                        setAlertContent({
                          severity: 'error',
                          title: 'Failed to log in',
                          body: 'Incorrect credentials. Please try again'
                        });

                        setIsAlertOpened(true);
                        window.scrollTo(0, 80);
                      });
                  }}>
                  {({
                    isSubmitting,
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleBlur,
                    validateField,
                    setFieldTouched,
                    setFieldValue,
                    setFieldError
                  }) => {
                    const handleNextClick = async (e) => {
                      e.preventDefault();
                      setIsLoadingNext(true);
                      // Manually validate the email field
                      await validateField('email');
                      const emailError = touched.email && errors.email;

                      if (!emailError) {
                        const response = await validateEmail(values.email);

                        if (response?.valid) {
                          setStep(2);
                        } else {
                          setFieldError('email', 'Email does not exist');
                        }
                      } else {
                        // Set the email field as touched
                        setFieldTouched('email', true);
                      }

                      setIsLoadingNext(false);
                    };

                    const handleOTPLogin = () => {
                      setLoginMethod('otp');
                      setFieldValue('loginMethod', 'otp');
                      const body = { email: values.email };

                      generateOTP(body)
                        .then((data) => {
                          setAlertContent({
                            severity: 'success',
                            title: 'Sent Successfully',
                            body: 'OTP has been sent to your number ending in ' + data?.phone_number
                          });
                        })
                        .catch((error) => {
                          setAlertContent({
                            severity: 'error',
                            title: 'Error in sending OTP',
                            body: 'Please try again'
                          });
                        });

                      setIsAlertOpened(true);
                    };

                    const handlePasswordLogin = () => {
                      setLoginMethod('password');
                      setFieldValue('loginMethod', 'password');
                      setIsAlertOpened(false);
                    };

                    return (
                      <Form>
                        {step === 1 ? (
                          <Fragment>
                            <TextInput
                              htmlFor="Email"
                              name="email"
                              label="Email"
                              title="Email"
                              variant="filled"
                              error={touched?.email && Boolean(errors?.email)}
                              helperText={touched?.email && errors?.email}
                              sx={{ marginBottom: 3 }}
                              value={values.email}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                            <Button
                              fullWidth={true}
                              variant="contained"
                              onClick={handleNextClick}
                              disabled={isLoadingNext}
                              endIcon={nextEndIcon}>
                              Next
                            </Button>
                          </Fragment>
                        ) : loginMethod === 'password' ? (
                          <PasswordLogin
                            handleOTPLogin={handleOTPLogin}
                            values={values}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            isSubmitting={isSubmitting}
                            email={values.email}
                            setAlertContent={setAlertContent}
                            setIsAlertOpened={setIsAlertOpened}
                            setStep={setStep}
                          />
                        ) : (
                          <OTPLogin
                            handlePasswordLogin={handlePasswordLogin}
                            values={values}
                            touched={touched}
                            errors={errors}
                            isSubmitting={isSubmitting}
                            setFieldValue={setFieldValue}
                            email={values.email}
                            setAlertContent={setAlertContent}
                            setIsAlertOpened={setIsAlertOpened}
                          />
                        )}
                      </Form>
                    );
                  }}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
